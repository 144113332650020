html {
  scroll-behavior: smooth;
}


.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 20px;
  background-color: rgba(0, 77, 64, 0.9);
  color: white;
  transition: background-color 0.3s, padding 0.3s;
}

.header.scrolled {
  padding: 10px 20px;
  background-color: rgba(0, 77, 64, 1);
}

.header.transparent {
  background-color: transparent;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
  transition: color 0.3s, transform 0.3s;
}

.logo:hover {
  color: #ffd700;
  transform: scale(1.05);
}


.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s, transform 0.3s;
  position: relative;
  padding-bottom: 5px;
}

.nav-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffd700;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.nav-links a:hover {
  color: #ffd700;
  transform: translateY(-2px);
}

.nav-links a:hover::after {
  transform: scaleX(1);
}






.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .nav-links {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: rgba(0, 77, 64, 0.95);
    padding: 20px;
    gap: 10px;
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none;
    transition: transform 0.3s, opacity 0.3s;
  }

  .nav-links.open {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto;
  }
}

