/* About.css */

.about-section {
  background: linear-gradient(to bottom, #e6f7e6, #c8e6c9);
  padding: 4rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2e7d32;
  text-align: center;
  margin-bottom: 2rem;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.text-content {
  margin-bottom: 2rem;
}

.text-content p {
  font-size: 1.125rem;
  color: #1b5e20;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.wonders-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2e7d32;
  text-align: center;
  margin-bottom: 1.5rem;
}

.wonders-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.wonder-item {
  display: flex;
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.wonder-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.wonder-icon {
  color: #4caf50;
  margin-right: 1rem;
  flex-shrink: 0;
  transition: transform 0.3s ease;
}





.wonder-content h4 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2e7d32;
  margin-bottom: 0.5rem;
}



@media (min-width: 768px) {
  .content-wrapper {
    flex-direction: row;
    align-items: flex-start;
  }

  .text-content {
    width: 50%;
    padding-right: 2rem;
    margin-bottom: 0;
  }

  .image-container {
    width: 50%;
  }

  .wonders-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Animation classes */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInUp {
  animation: fadeInUp 0.5s ease-out forwards;
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-popIn {
  animation: popIn 0.5s cubic-bezier(0.26, 0.53, 0.74, 1.48) forwards;
}