/* Hero.css */

/* Add this to your CSS file */
html {
  scroll-behavior: smooth;
}


.hero-section {
  position: relative;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Hide anything outside of the container */
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(60%);
  z-index: 1; /* Ensure video is behind the overlay and content */
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay with 50% opacity */
  z-index: 1; /* Ensure overlay is above the video but below the content */
}

.hero-content {
  position: absolute; /* Absolute positioning to overlay the video */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the content */
  color: #fff; /* Ensure text color contrasts with the video */
  text-align: center;
  z-index: 2; /* Ensure content is above the video */
  padding: 20px;
}

.button-container {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.cta-button, .secondary-button {
  background-color: #4CAF50; /* Choose a vivid color */
  border: none;
  padding: 15px 30px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
  margin-right: 10px;
}

.cta-button:hover, .secondary-button:hover {
  background-color: #45a049; /* Darker shade on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.discover-buttons, .social-buttons {
  display: none;
  justify-content: center;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.button-container:hover .cta-button, .button-container:hover .secondary-button {
  opacity: 0;
  pointer-events: none;
}

.button-container:hover .discover-buttons, .button-container:hover .social-buttons {
  display: flex;
}

.discover-button, .social-button {
  background-color: #4CAF50;
  color: #fff;
  padding: 15px 20px;
  margin: 0 10px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
}

.discover-button:hover, .social-button:hover {
  background-color: #45a049;
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.scroll-indicator {
  position: absolute;
  bottom: -160px; /* Distance from the bottom of the hero section */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  animation: smooth-bounce 1.5s infinite;
  z-index: 2; /* Ensure it's above the overlay but below the button */
}

@keyframes smooth-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Specific colors for social buttons */
/* .whatsapp {
  background-color: #25D366;
  color: #fff;
}

.whatsapp:hover {
  background-color: #1EBEA5;
} */

.instagram {
  background-color: #E1306C;
  color: #fff;
}

.instagram:hover {
  background-color: #C13584;
}

.facebook {
  background-color: #3b5998;
  color: #fff;
}

.facebook:hover {
  background-color: #2D4373;
}
