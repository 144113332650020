.gallery {
  padding: 80px 50px;
  background-color: #e0f7fa;
  text-align: center;
  overflow: hidden;
}

.gallery h2 {
  font-size: 2.5rem;
  color: #004d40;
  margin-bottom: 60px;
  position: relative;
  display: inline-block;
}

.gallery h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: #004d40;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.gallery-item {
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

@media (max-width: 768px) {
  .gallery {
    padding: 60px 30px;
  }

  .gallery h2 {
    font-size: 2rem;
    margin-bottom: 40px;
  }

  .gallery-grid {
    gap: 20px;
  }
}