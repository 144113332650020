/* Packages.css */

.packages-section {
  background-color: #e8f5e9;
  padding: 4rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.section-title {
  font-size: 2.5rem;
  color: #2e7d32;
  text-align: center;
  margin-bottom: 2rem;
}

.packages-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.package-category {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.category-header {
  padding: 1rem 1.5rem;
  background-color: #4caf50;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.category-header:hover {
  background-color: #45a049;
}

.category-header h3 {
  margin: 0;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.category-icon {
  margin-right: 0.5rem;
}

.category-content {
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  overflow: hidden;
}

.package-card {
  background-color: #f1f8e9;
  border-radius: 8px;
  overflow: hidden;
  width: calc(33.33% - 1rem);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.package-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 66.67%; /* 3:2 Aspect Ratio */
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.package-card:hover .image-container img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(76, 175, 80, 0.2);
  transition: background-color 0.3s ease;
}

.package-card:hover .overlay {
  background-color: rgba(76, 175, 80, 0.1);
}

.package-details {
  padding: 1.5rem;
}

.package-details h4 {
  margin: 0 0 0.5rem 0;
  font-size: 1.3rem;
  color: #2e7d32;
}

.package-details p {
  font-size: 1rem;
  color: #4caf50;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.package-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #666;
}

.package-meta span {
  display: flex;
  align-items: center;
}

.package-meta svg {
  margin-right: 0.25rem;
}

.package-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.package-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #2e7d32;
}

.learn-more-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #4caf50;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.learn-more-button:hover {
  background-color: #45a049;
}

.package-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eco-cert {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #4caf50;
}

.eco-cert svg {
  margin-right: 0.25rem;
}

@media (max-width: 1024px) {
  .package-card {
    width: calc(50% - 0.75rem);
  }
}

@media (max-width: 768px) {
  .package-card {
    width: 100%;
  }
}