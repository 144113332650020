/* Footer.css */
.footer {
  background-color: #004d40;
  color: white;
  padding: 40px 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  position: relative;
  flex-wrap: wrap;
}

.footer h3 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  position: relative;
  display: inline-block;
}

.footer h3::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #ffd700;
}

.contact-info, .social-media {
  flex: 1;
  min-width: 250px;
  margin-bottom: 20px;
}

.contact-info p {
  margin: 10px 0;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.contact-info p svg {
  margin-right: 10px;
  color: #ffd700;
}

.social-icons {
  display: flex;
  gap: 20px;
}

.social-icons a {
  color: white;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #ffd700;
}

.whatsapp-container {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.whatsapp {
  font-size: 2.5rem; /* Increase the font size */
  color: #25D366;
  transition: transform 0.3s ease;
}

.scroll-to-top {
  background-color: #4CAF50;
  border: none;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.scroll-to-top:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer h3::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .contact-info p {
    justify-content: center;
  }

  .social-icons {
    justify-content: center;
  }
}
