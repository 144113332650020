.package-detail {
  font-family: 'Arial, sans-serif';
  color: #2c3e50;
  background-color: #f0f4f8;
}

.back-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s;
  margin-bottom: 20px;
}

.back-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

.back-button svg {
  margin-right: 5px;
}

.package-hero {
  height: 60vh;
  position: relative;
  overflow: hidden;
}

.image-slider {
  height: 100%;
  position: relative;
}

.image-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.slider-button:hover {
  background-color: rgba(255, 255, 255, 0.8);
  transform: scale(1.1);
}

.slider-button.prev {
  left: 20px;
}

.slider-button.next {
  right: 20px;
}

.package-hero h1 {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  font-size: 3rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.package-content {
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
}

.package-content h2 {
  font-size: 2rem;
  margin-top: 30px;
  margin-bottom: 15px;
  border-bottom: 2px solid #4caf50;
  display: inline-block;
  padding-bottom: 5px;
  color: #2c3e50;
}

.package-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.read-more {
  background: none;
  border: none;
  color: #4caf50;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s;
}

.read-more:hover {
  color: #45a049;
}

.package-content ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
}

.reviews .review {
  background-color: #e8f5e9;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  transition: transform 0.3s;
}

.reviews .review:hover {
  transform: translateY(-3px);
}

.reviews .review p {
  margin: 0 0 10px 0;
  font-style: italic;
}

.review-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stars {
  display: flex;
}

.similar-packages-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.similar-package {
  cursor: pointer;
  transition: transform 0.3s;
  display: block;
}

.similar-package:hover {
  transform: translateY(-5px);
}

.similar-package img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
}

.similar-package h3 {
  margin-top: 10px;
  font-size: 1.1rem;
  color: #2c3e50;
}

.booking-section {
  text-align: center;
  margin-top: 40px;
}

.booking-button {
  padding: 15px 30px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.booking-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .package-hero h1 {
    font-size: 2rem;
  }

  .similar-packages-grid {
    grid-template-columns: 1fr;
  }

  .package-content {
    padding: 20px 10px;
  }
}