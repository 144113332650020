/* PopularDestinations.css */

.popular-destinations {
  background-color: #e8f5e9;
  padding: 4rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.section-title {
  font-size: 2.5rem;
  color: #2e7d32;
  text-align: center;
  margin-bottom: 2rem;
}

.destinations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

.destination-card {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.destination-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 66.67%; /* 3:2 Aspect Ratio */
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.destination-card:hover .image-container img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(46, 125, 50, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.destination-card:hover .overlay {
  opacity: 1;
}

.map-icon {
  color: white;
  width: 40px;
  height: 40px;
}

.destination-card h3 {
  font-size: 1.5rem;
  color: #2e7d32;
  margin: 1rem;
}

.destination-card p {
  font-size: 1rem;
  color: #4caf50;
  margin: 0 1rem 1rem;
  line-height: 1.5;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .destinations-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}